import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

const routes = [
    {
        path: '/',
        name: 'Home',
        component: () => import(/* webpackChunkName: "walkInto" */ '../views/Home.vue')
    },
    {
        path: '/walkInto',
        name: 'walkInto',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "walkInto" */ '../views/walkInto.vue')
    },
    {
        path: '/industryTrends',
        name: 'industryTrends',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "industryTrends" */ '../views/industryTrends.vue')
    },
    {
        path: '/industryTrendsDetails/:type',
        name: 'industryTrendsDetails',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "industryTrends" */ '../views/industryTrendsDetails.vue')
    },
    {
        path: '/coreActivity',
        name: 'coreActivity',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "coreActivity" */ '../views/coreActivity.vue')
    },
    {
        path: '/contactUs',
        name: 'contactUs',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "contactUs" */ '../views/contactUs.vue')
    }
]

const router = new VueRouter({
    routes,
    scrollBehavior() {
        return {x: 0, y: 0}
    }
})

export default router

