<template>
  <div>
    <div class="header">
      <img src="~@/assets/img/logo@2x.png" alt="logo" @click="onClick(0)" style="cursor: pointer">
      <div class="nav">
        <span v-for="(item,index) in navData" :key="index" :class="{activate:activate === index}"
              @click="onClick(index)">
          {{ item.title }}
        </span>
      </div>
    </div>
    <div style="position: relative;top: 79px;z-index: 1;">
      <keep-alive>
        <router-view/>
      </keep-alive>
      <div class="footer">
        <div>
        <span><span @click="onClick(0)">首页</span>&nbsp;&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;&nbsp;<span
            @click="onClick(1)">走进麦菲斯</span>&nbsp;&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;&nbsp;<span
            @click="onClick(2)">核心业务</span>&nbsp;&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;&nbsp;<span
            @click="onClick(3)">行业动态</span>&nbsp;&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;&nbsp;<span
            @click="onClick(4)">联系我们</span></span>
          <span>Copyright ©2021-2022 Xi 'an Bowu Information Technology Co., LTD All Rights Reserved 西安麦菲斯医疗科技有限公司
            <!--            <img src="~@/assets/img/gs.png"/><a href="https://beian.miit.gov.cn" target="_blank">&lt;!&ndash;陕ICP备2022001347号-1&ndash;&gt;陕ICP备2022001347号</a>-->
            </span>
        </div>
        <div><span><img src="~@/assets/img/gs.png"/><a href="https://beian.miit.gov.cn"
                                                       target="_blank">陕ICP备2022001347号-1</a></span></div>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: 'Home',
  data() {
    return {
      navData: [
        {title: '首页', path: '/'},
        {title: '走进麦菲斯', path: '/walkInto'},
        {title: '核心业务', path: '/coreActivity'},
        {title: '行业动态', path: '/industryTrends'},
        {title: '联系我们', path: '/contactUs'}
      ],
      activate: 0
    }
  },
  mounted() {
    //刷新后选中对应路由的菜单
    setTimeout(() => {
      this.activate = this.navData.findIndex(item => {
        return item.path === this.$route.path;
      });
    }, 100)
  },
  methods: {
    onClick(index) {
      this.activate = index;
      this.$router.push(this.navData[index].path)
    }
  }
}
</script>

<style>
@import '~@/assets/scss/global.scss';

html, body {
  margin: 0;
  padding: 0;
}

</style>
<style lang="scss" scoped>
.header {
  height: 79px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 90px;
  position: fixed;
  top: 0;
  z-index: 999;
  width: calc(100% - 90px - 90px);
  background: #FFFFFF;

  img {
    width: 210px;
    height: 42px;
  }

  .nav {
    width: 40%;
    display: flex;
    justify-content: space-between;

    span {
      display: inline-block;
      font-size: 16px;
      color: #4C4C4C;
      cursor: pointer;
      height: 77px;
      line-height: 77px;

      &.activate {
        color: #BE9A7E;
        border-bottom: 2px solid #BE9A7E;
      }
    }
  }
}

.footer {
  background: url('~@/assets/img/footer@2x.png') no-repeat;
  padding: 93px 0;
  text-align: center;

  & > div {
    & > span {
      color: #FFFFFF !important;
      opacity: 0.5;

      a {
        color: #FFFFFF !important;
      }

      img {
        vertical-align: bottom;
        padding: 0 7px;
      }

      & > span {

        cursor: pointer;
      }

      &:first-child {
        font-size: 14px;
        line-height: 21px;
        font-weight: 500;
      }

      &:last-child {
        display: inline-block;
        padding-left: 40px;
        font-size: 12px;
        line-height: 16px;
      }
    }
  }
}
</style>
